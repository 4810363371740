export function circleScroll(){
    document.addEventListener("DOMContentLoaded", function () {
        const circleSvg = document.getElementById("circleSvg");
        const text1 = document.getElementById("text1");
        const text2 = document.getElementById("text2");
    
        const observerOptions = {
            root: null, // В качестве корня используется viewport
            rootMargin: "0px 0px -100% 0px", // Создает область, равную половине экрана сверху и снизу
            threshold: 0 // Процент пересечения для срабатывания
        };
    
        const observerCallback = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    circleSvg.classList.remove("back-circle-block");
                    circleSvg.classList.add("circle-block");
                    text1.classList.add("hidden");
                    text1.classList.remove("visible");
                    text2.classList.add("visible");
                    text2.classList.remove("hidden");
                } else {
                    circleSvg.classList.remove("circle-block");
                    circleSvg.classList.add("back-circle-block");
                    text1.classList.add("visible");
                    text1.classList.remove("hidden");
                    text2.classList.add("hidden");
                    text2.classList.remove("visible");
                }
            });
        };
    
        const observer = new IntersectionObserver(observerCallback, observerOptions);
        const target = document.querySelector(".circle-section__container");
        observer.observe(target);
    });
    
}