gsap.registerPlugin(ScrollTrigger, ScrollToPlugin) 
export function parallax() {
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: "#team",
            start: "top bottom-=50",
            end: "bottom top-=200",
            scrub: true,
        }
    });

    gsap.utils.toArray(".parallax").forEach(layer => {
        const depth = layer.dataset.depth;
        const movement = -(layer.offsetHeight * depth);
        tl.to(layer, {y: movement, ease: "none"}, 0);
    });
}