import { getRem } from "../utils.js";

export function howWeWork() {
    const section = document.querySelector('.how-we-work-section');
    const phases = document.querySelectorAll('.__hww__animation__phase');

    const sectionMobile = document.querySelector('.how-we-work-section_mobile-only');
    const phasesMobile = document.querySelectorAll('.__hww__animation__phase_mobile');

    let animationPlayed = false;

    let scrollingToBottom = true;

    let startY = 0;
    let endY = 0;

    const handleTouchStart = (event) => {
        startY = event.touches[0].clientY;
    };

    const handleTouchMove = (event) => {
        endY = event.touches[0].clientY;
    };

    const handleTouchEnd = (event) => {
        const deltaY = endY - startY;
        if (deltaY > 0) {
            scrollingToBottom = false;
        } else if (deltaY < 0) {
            scrollingToBottom = true;
        }
        startY = 0;
        endY = 0;
    };

    document.addEventListener('touchstart', handleTouchStart, { passive: false });
    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('touchend', handleTouchEnd, { passive: false });

    const scroller = (event) => {
        event.preventDefault();
        document.documentElement.style.overflow = 'hidden';

        const delta = event.deltaY;

        if(window.innerWidth > 768) {
            phases.forEach(phase=>{
                const active = phase.dataset.active;
                const phaseNumber = Number(phase.dataset.phase);
    
                if(active == 'true') {
                    if(delta < 0 && phaseNumber - 1 == 0) {
                        removeAllListeners();
                    }
                    if(delta > 0 && phaseNumber + 1 == 6) {
                        removeAllListeners();
                    }
    
                    if (delta > 0 && !animationPlayed && phaseNumber + 1 !== 6) {
                        animationPlayed = true;
    
                        appear(phases, phaseNumber + 1);
                        changeInnerHtml(phaseNumber + 1);
                        fadeOut(phases, phaseNumber);
    
                        setTimeout(() => {
                            animationPlayed = false;
                        }, 500);
                    } else if(delta < 0 && !animationPlayed && phaseNumber - 1 !== 0)  {
                        animationPlayed = true;
    
                        appear(phases, phaseNumber - 1);
                        changeInnerHtml(phaseNumber - 1);
                        fadeOut(phases, phaseNumber);
    
                        setTimeout(() => {
                            animationPlayed = false;
                        }, 500);
                    }
                };
            });
        }
        if(window.innerWidth < 768) {
            phasesMobile.forEach(phase=>{
                const active = phase.dataset.active;
                const phaseNumber = Number(phase.dataset.phase);

                if(active == 'true') {
                    if(!scrollingToBottom && phaseNumber - 1 == 0) {
                        removeAllListeners();
                    }
                    if(scrollingToBottom && phaseNumber + 1 == 6) {
                        removeAllListeners();
                    }

                    if(scrollingToBottom && !animationPlayed && phaseNumber + 1 !== 6 && window.innerWidth < 768) {
                        animationPlayed = true;
        
                        appear(phasesMobile, phaseNumber + 1);
                        changeInnerHtml(phaseNumber + 1);
                        fadeOut(phasesMobile, phaseNumber);
        
                        setTimeout(() => {
                            animationPlayed = false;
                        }, 500);
                    } else if(!scrollingToBottom && !animationPlayed && phaseNumber + 1 !== 0 && window.innerWidth < 768) {
                        animationPlayed = true;
        
                        appear(phasesMobile, phaseNumber - 1);
                        changeInnerHtml(phaseNumber - 1);
                        fadeOut(phasesMobile, phaseNumber);
        
                        setTimeout(() => {
                            animationPlayed = false;
                        }, 500);
                    };
                }
            });
        };
    };

    const scrollToSection = () => {
        let rect = section.getBoundingClientRect();
        
        if(window.innerWidth < 768) rect = sectionMobile.getBoundingClientRect();

        const topPositionMiddle = rect.top - rect.height / 2;
        const bottomPositionMiddle = rect.bottom - rect.height / 2;
        if(window.innerWidth > 768 && topPositionMiddle < 0 && bottomPositionMiddle > 0) {
            gsap.to(window, { duration: 0.5, scrollTo: { y: section, offsetY: getRem() * 2 } });

            document.addEventListener('wheel', scroller, { passive: false });
            document.addEventListener('touchmove', scroller, { passive: false });
        }else if (window.innerWidth < 768 && (rect.top - rect.height / 3) < 0 && (rect.bottom - rect.height / 3) > 0) {
            gsap.to(window, { duration: 0.5, scrollTo: { y: sectionMobile, offsetY: getRem() * 10 } });

            document.addEventListener('wheel', scroller, { passive: false });
            document.addEventListener('touchmove', scroller, { passive: false });
        }
    };

    document.addEventListener('wheel', scrollToSection, { passive: false });
    document.addEventListener('touchmove', scrollToSection, { passive: false });


    function removeAllListeners() {
        document.removeEventListener('wheel', scrollToSection, { passive: false });
        document.removeEventListener('touchmove', scrollToSection, { passive: false });
        document.removeEventListener('wheel', scroller, { passive: false });
        document.removeEventListener('touchmove', scroller, { passive: false });
        document.documentElement.style.overflow = null;
        setTimeout(() => {
            document.addEventListener('wheel', scrollToSection, { passive: false });
            document.addEventListener('touchmove', scrollToSection, { passive: false });
        }, 1500);
    };
}

function appear(items, number) {
    items.forEach(item=>{
        const phase = Number(item.dataset.phase);
        if(phase == number) {
            console.log(number);
            item.style.transform = 'translateY(0)';
            item.style.opacity = '1';
            item.dataset.active = 'true';
        };
    });
}
function fadeOut(items, number) {
    items.forEach(item=>{
        const phase = Number(item.dataset.phase);
        if(phase == number) {
            item.style.transform = 'translateY(-3rem)';
            item.style.opacity = '0';
            item.dataset.active = null;
            setTimeout(() => {
                item.style.transform = 'translateY(10rem)';;
            }, 500);
        };
    });
}

function changeInnerHtml(number) {
    let imageBlock = document.querySelector('.how-we-work-section__laptop__image');

    if (window.innerWidth < 768) {
        imageBlock = document.querySelector('.how-we-work-section_mobile-only__info-block__images-block__laptop__file');
    }

    switch (number) {
        case 1:
            imageBlock.innerHTML = `
                <embed src="./src/assets/analysis.pdf#toolbar=0&navpanes=0&scrollbar=0" type="application/pdf" width="600" height="400">
            `;
            break;
        case 2:
            imageBlock.innerHTML = `
                <video autoplay="autoplay" playsinline muted loop src="./src/assets/videos/strategy_video.mp4"></video>
            `;
            break;
        case 3:
            imageBlock.innerHTML = `
                <video autoplay="autoplay" playsinline muted loop src="./src/assets/videos/main_video.mp4"></video>
            `
            break;
    }
}