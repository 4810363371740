import { getRem } from "../utils.js";

export function advantagesScroll() {
    const section = document.querySelector('.advantages-section');
    const phases = document.querySelectorAll('.__advantages__animation__phase');

    let animationPlayed = false;


    let scrollingToBottom = true;

    const handleTouchStart = (event) => {
        startY = event.touches[0].clientY;
    };

    const handleTouchMove = (event) => {
        endY = event.touches[0].clientY;
    };

    const handleTouchEnd = (event) => {
        const deltaY = endY - startY;
        if (deltaY > 0) {
            scrollingToBottom = false;
        } else if (deltaY < 0) {
            scrollingToBottom = true;
        }
        startY = 0;
        endY = 0;
    };

    document.addEventListener('touchstart', handleTouchStart, { passive: false });
    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('touchend', handleTouchEnd, { passive: false });

    const scroller = (event) => {
        event.preventDefault();
        document.documentElement.style.overflow = 'hidden';

        const delta = event.deltaY;


        phases.forEach(phase=>{
            const active = phase.dataset.active;
            const phaseNumber = Number(phase.dataset.phase);


            if(active == 'true') {
                if (delta > 0 && !animationPlayed && window.innerWidth > 768) {
                    animationPlayed = true;

                    allAnimationsActivate(phaseNumber + 1);

                    setTimeout(() => {
                        animationPlayed = false;
                    }, 1000);
                } else if(delta < 0 && !animationPlayed && window.innerWidth > 768)  {
                    animationPlayed = true;

                    allAnimationsActivate(phaseNumber - 1);

                    setTimeout(() => {
                        animationPlayed = false;
                    }, 1000);
                } else if (window.innerWidth < 768 && !animationPlayed && scrollingToBottom) {
                    animationPlayed = true;

                    allAnimationsActivate(phaseNumber + 1, scrollingToBottom);

                    setTimeout(() => {
                        animationPlayed = false;
                    }, 500);
                } else if (window.innerWidth < 768 && !animationPlayed && !scrollingToBottom) {
                    animationPlayed = true;

                    allAnimationsActivate(phaseNumber - 1, scrollingToBottom);

                    setTimeout(() => {
                        animationPlayed = false;
                    }, 500);
                }
            }
        });
    };

    const scrollToSection = () => {
        const rect = section.getBoundingClientRect();


        const topPositionMiddle = rect.top - rect.height / 2;
        const bottomPositionMiddle = rect.bottom - rect.height / 2;
        if(topPositionMiddle < 0 && bottomPositionMiddle > 0 && window.innerWidth > 768) {
            gsap.to(window, { duration: 0.5, scrollTo: { y: section, offsetY: getRem() * 4.2 } });

            document.addEventListener('wheel', scroller, { passive: false });
            document.addEventListener('touchmove', scroller, { passive: false });
        } else if (window.innerWidth < 768 && (rect.top - rect.height / 3) < 0 && (rect.bottom - rect.height / 3) > 0) {
            gsap.to(window, { duration: 0.5, scrollTo: { y: section, offsetY: getRem() * 2 } });

            document.addEventListener('wheel', scroller, { passive: false });
            document.addEventListener('touchmove', scroller, { passive: false });
        };
    };

    document.addEventListener('wheel', scrollToSection, { passive: false });
    document.addEventListener('touchmove', scrollToSection, { passive: false });

    const main = document.querySelector('.advantages-section__main');

    function allAnimationsActivate(number, scrollingBottom) {

        const section = document.querySelector('.advantages-section');
        const items = section.querySelectorAll('.advantages-section__main__item');


        switch (number) {
            case 0:
                items.forEach(item=>setAllDefault(item));

                main.dataset.phase = 1;

                removeAllListeners()
                break;
            case 1:
                items.forEach(item=>setAllDefault(item));

                main.dataset.phase = 1;

                removeAllListeners()
                break;
            case 2:
                items.forEach(item=>{
                    setItemActive(item);
                    analysisAnimate(item);
                });
                if(scrollingBottom) {
                    scrollingToBottomListAnimate(2)
                }else if (!scrollingToBottom) {
                    scrollingToTopListAnimate(2)
                }
                break
            case 3:
                items.forEach(item=>{
                    setItemActive(item);
                    marketingAnimate(item);
                });
                if(scrollingBottom) {
                    scrollingToBottomListAnimate(3)
                }else if (!scrollingToBottom) {
                    scrollingToTopListAnimate(3)
                }
                break;
            case 4:
                items.forEach(item=>{
                    setItemActive(item);
                    developAnimate(item);
                });
                if(scrollingBottom) {
                    scrollingToBottomListAnimate(4)
                } else if (!scrollingToBottom) {
                    scrollingToTopListAnimate(4)
                }
                break;
            case 5:
                items.forEach(item=>{
                    setItemActive(item);
                    packingAnimate(item);
                });
                if(scrollingBottom) {
                    scrollingToBottomListAnimate(5)
                }else if (!scrollingToBottom) {
                    scrollingToTopListAnimate(5)
                }
                break;
            case 6:
                items.forEach(item=>setAllDefault(item));

                main.dataset.phase = 6;

                removeAllListeners()
                break;
            case 7:
                removeAllListeners()
                break;
        }
    };

    function removeAllListeners() {
        document.removeEventListener('wheel', scrollToSection, { passive: false });
        document.removeEventListener('touchmove', scrollToSection, { passive: false });
        document.removeEventListener('wheel', scroller, { passive: false });
        document.removeEventListener('touchmove', scroller, { passive: false });
        document.documentElement.style.overflow = null;
        setTimeout(() => {
            document.addEventListener('wheel', scrollToSection, { passive: false });
            document.addEventListener('touchmove', scrollToSection, { passive: false });
        }, 1500);
    };
}

const main = document.querySelector('.advantages-section__main');
const mobileLists = document.querySelectorAll('.advantages-section__main__info-list');

function setAllDefault(item) {

    main.dataset.active = 'true';

    item.dataset.active = 'false';

    if(window.innerWidth > 768) {
        const image = item.querySelector('.advantages-section__main__image');
        const infoList = item.querySelector('.advantages-section__main__item__info-block__list');

        infoList.style.opacity = null;

        image.style.maxWidth = null;
        image.style.maxHeight = null;

        item.style.width = null;
        item.style.height = null;
        item.style.top = null;
        item.style.bottom = null;
        item.style.left = null;
        item.style.right = null;
    } else {
        item.style.opacity = null;
        mobileLists.forEach(list=>{
            listDisappear(list);
        });
    }
}

function setItemActive(item) {
    main.dataset.active = 'false';
    if(window.innerWidth > 768) {
        const image = item.querySelector('.advantages-section__main__image');
        const infoList = item.querySelector('.advantages-section__main__item__info-block__list');

        infoList.style.opacity = 1;

        image.style.maxWidth = '50rem';
        image.style.maxHeight = '50rem';

        item.style.width = 'calc(100% - 8.5rem)';
        item.style.height = 'calc(100dvh - 11rem)';
    }
}

function analysisAnimate(item) {
    if(window.innerWidth > 768) {
        if(item.classList.contains('analysis')) {
            item.style.top = '6.5rem';
            item.style.left = '7.5rem';
    
            setTimeout(() => {
                item.dataset.active = 'true';
            }, 50);
        } else if(item.classList.contains('marketing')) {
            item.dataset.active = 'false';
    
            item.style.right = '-100%';
            item.style.top = '6.5rem';
        } else if (item.classList.contains('packing')) {
            item.dataset.active = 'false';
    
            item.style.top = '105dvh';
            item.style.left = '7.5rem';
        } else if (item.classList.contains('develop')) {
            item.dataset.active = 'false';
    
            item.style.top = '105dvh';
            item.style.right = '-100%';
        }
    } else {
        if(item.classList.contains('analysis')) {
            item.style.opacity = 1;
            setTimeout(() => {
                item.dataset.active = 'true';
            }, 50);
        } else {
            item.dataset.active = 'false';
            item.style.opacity = .1;
        }
    }
}
function marketingAnimate(item) {
    if(window.innerWidth > 768) {
        if(item.classList.contains('analysis')) {
            item.dataset.active = 'false';
    
            item.style.top = '6.5rem';
            item.style.left = '-100%';
        } else if(item.classList.contains('marketing')) {
            item.style.right = '7.5rem';
            item.style.top = '6.5rem';
    
            setTimeout(() => {
                item.dataset.active = 'true';
            }, 50);
        } else if (item.classList.contains('packing')) {
            item.dataset.active = 'false';
    
            item.style.top = '105dvh';
            item.style.left = '-100%';
        } else if (item.classList.contains('develop')) {
            item.dataset.active = 'false';
    
            item.style.top = '105dvh';
            item.style.right = '7.5rem';
        }
    } else {
        if(item.classList.contains('marketing')) {
            item.style.opacity = 1;
            setTimeout(() => {
                item.dataset.active = 'true';
            }, 50);
        } else {
            item.dataset.active = 'false';
            item.style.opacity = .1;
        }
    }
}
function developAnimate(item) {
    if(window.innerWidth > 768) {
        if(item.classList.contains('analysis')) {
            item.dataset.active = 'false';
    
            item.style.top = '-105dvh';
            item.style.left = '-100%';
        } else if(item.classList.contains('marketing')) {
            item.dataset.active = 'false';
    
            item.style.right = '7.5rem';
            item.style.top = '-105dvh';
        } else if (item.classList.contains('packing')) {
            item.dataset.active = 'false';
    
            item.style.left = '-100%';
            item.style.top = '6.5rem';
        } else if (item.classList.contains('develop')) {
            item.style.top = '6.5rem';
            item.style.right = '7.5rem';
    
            setTimeout(() => {
                item.dataset.active = 'true';
            }, 50);
        }
    } else {
        if(item.classList.contains('develop')) {
            item.style.opacity = 1;
            setTimeout(() => {
                item.dataset.active = 'true';
            }, 50);
        } else {
            item.dataset.active = 'false';
            item.style.opacity = .1;
        }
    }
}
function packingAnimate(item) {
    if(window.innerWidth > 768) {
        if(item.classList.contains('analysis')) {
            item.dataset.active = 'false';
    
            item.style.top = '-105dvh';
            item.style.left = '0';
        } else if(item.classList.contains('marketing')) {
            item.dataset.active = 'false';
    
            item.style.right = '-100%';
            item.style.top = '-105dvh';
        } else if (item.classList.contains('packing')) {
            item.style.left = '7.5rem';
            item.style.top = '6.5rem';
    
            setTimeout(() => {
                item.dataset.active = 'true';
            }, 50);
        } else if (item.classList.contains('develop')) {
            item.dataset.active = 'false';
    
            item.style.top = '6.5rem';
            item.style.right = '-100%';
        }
    }  else {
        if(item.classList.contains('packing')) {
            item.style.opacity = 1;
            setTimeout(() => {
                item.dataset.active = 'true';
            }, 50);
        } else {
            item.dataset.active = 'false';
            item.style.opacity = .1;
        }
    }
}



function scrollingToTopListAnimate(number) {
    mobileLists.forEach(list=>{
        if(Number(list.dataset.phase) == number) {
            listAppear(list);
        } else if(Number(list.dataset.phase) == number + 1) {
            listDisappear(list);
        }
    });
}
function scrollingToBottomListAnimate(number) {
    mobileLists.forEach(list=>{
        if(Number(list.dataset.phase) == number) {
            listAppear(list);
        } else if(Number(list.dataset.phase) == number - 1) {
            listDisappear(list);
        }
    });
}

function listAppear(list) {
    list.style.opacity = 1;
    list.style.transform = 'translateY(0)';
}
function listDisappear(list) {
    list.style.opacity = 0;
    list.style.transform = 'translateY(-5rem)';
    setTimeout(() => {
        list.style.opacity = null;
        list.style.transform = null;
    }, 500);
}