import '../styles/main.scss';

import { scrollTrigger } from "./modules/animations/scrollTrigger.js";
import { parallax } from "./modules/paralax.js";
import { circleScroll } from "./modules/circleScroll.js";
import { benefits } from "./modules/animations/benefits.js";


scrollTrigger()
parallax()
circleScroll()
benefits()