export function counter() {
    document.querySelectorAll('.__counter__animation').forEach(el=> {
        const dataValue = el.getAttribute('data-value');
        const dataDuration = el.getAttribute('data-duration');

        ScrollTrigger.create({
            trigger: el,
            start: 'top bottom',
            onEnter: () => {
                let Cont = { val: 0 },
                NewVal = dataValue;

                gsap.to(Cont, Number(dataDuration), {
                val: NewVal,
                roundProps: "val",
                onUpdate: function () {
                    el.innerHTML = Cont.val;
                }
                });
            },
        });
    });
}