import { advantagesScroll } from "./advantagesScroll.js"
import { howWeWork } from "./howWeWork.js"
import { changerAnimation } from "./changerAnimation.js"
import { textEnter } from "./textEnter.js"
import { counter } from "./counter.js"

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin) 

export function scrollTrigger() {
    advantagesScroll()
    howWeWork()
    changerAnimation()
    textEnter()
    counter()
}