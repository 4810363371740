import { elementInViewport } from "../utils.js";

export function textEnter() {
    const triggers = document.querySelectorAll('.__enter__animation__trigger');
    if(window.innerWidth > 768) {
        document.addEventListener('scroll',()=>{
            triggers.forEach(trigger=>{
                if(elementInViewport(trigger)) {
                    trigger.classList.remove('__enter__animation__triggered');
                    trigger.classList.add('__enter__animation__triggered');
                };
            });
        });
    }
}