export function changerAnimation() {
    const mainChangerAnimation = document.querySelector('.__changer__animation');
    const animatedElems = Array.from(mainChangerAnimation.children);

    const firstElem = animatedElems[0];
    const secondElem = animatedElems[1];
    const thirdElem = animatedElems[2];
    const fourthElem = animatedElems[3];

    let animatedNumber = 0;

    const updateNumber = () => {
        animatedNumber++;
        if (animatedNumber > 4) {
            animatedNumber = 1;
        }

        switch (animatedNumber) {
            case 1:
                firstElem.classList.remove('enter__animation_delayed');
                slideOut(firstElem);
                showIn(secondElem);
                break;
            case 2:
                slideOut(secondElem);
                showIn(thirdElem);
                break;
            case 3:
                slideOut(thirdElem);
                showIn(fourthElem);
                break;
            case 4:
                slideOut(fourthElem);
                showIn(firstElem);
                break;
        }
    };

    setInterval(updateNumber, 3500);
}

function slideOut(div) {
    div.style.transform = 'translate(-100%,-50%)';
    setTimeout(() => {
        div.style.display = 'none';
        div.style.transform = 'translateY(-50%)';
    }, 1500);
}
function showIn(div) {
    setTimeout(() => {
        div.style.display = 'flex';
        div.classList.remove('enter__animation');
        div.classList.add('enter__animation');
    }, 1500);
}